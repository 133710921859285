import React from 'react';

export default function Experience() {
  return (
    <>
      <h2>Experience</h2>
      <br/>
      <h4>Team Lead, Software Development</h4>
      <a href={'https://www.mosaiclearning.com/'}>Mosiac Learning</a>
      <hr className="hr-accent"/>
      <p>
        At Mosaic Learning I helped build a virtual conference and meeting application. We deployed using all serverless technology 
        and handled large online conferences that were previously in person. Working at Mosaic increased my skill in React, Node and 
        many AWS services such as Lambda and ECS. I became experienced using Serverless Framework and Cloud Formation to architect and deploy
        our application. 
      </p>
      <p>
        I helped create a streaming service using ECS, FFMPEG and IVS, a new streaming service from AWS, that allowed users to 
        join an online video call and stream it out to thousands of virtual attendees. The service handled user messages and polling 
        to help interaction between presenters and attendees. 
      </p>
      <br />
      <h4>Application Developer</h4>
      <a href={'https://www.kasmweb.com'}>Kasm Technologies</a>
      <hr className="hr-accent"/>
      <p>
        At Kasm I helped build a web application for application isolation/VDI
        instances to provide secure, isolated browsing/VDI solutions.
      </p>
      <p>
        My main job was developing the UI with React and javascript,
        and the backend API with python. I often use Docker, and 
        Docker-Compose as well as Google Cloud Platform to run development servers.
      </p>
      <p>
        One of my early tasks with Kasm was to create an upload server from the clients
        browser to our containerized environment. I made a small file server using flask and python that was containerized.
        It had the ability to handle large chunked file uploads and the building was automated using CD.
      </p>
      <p>
        I built a reporting dashboard to show user statistics and usage.
        We used SQL, chart.js and reactstrap widgets to create a bright responsive dashboard using the log
        data from the application.
      </p>
      <p>
        I was in charge of implementing two-factor and SAML 2.0 authentication methods for web
        portal. I used a library to read the xml data for the saml response and routed the traffic through
        the API for the right responses.
      </p>
      <p>
        I provided youtube tutorials/walkthroughs for guidance with installations of our server and simple ads for our website.
      </p>
    </>
  );   
}