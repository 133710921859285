import React, { useRef } from 'react';
import './App.css';
import { Row, Col } from "reactstrap";
import NavBar from "./Components/Nav";
import Header from "./Components/Header";
import Experience from './Components/Experience'
import Story from './Components/Story'
import Skills from './Components/Skills'
import Projects from './Components/Projects'
import Footer from './Components/Footer'

function App() {
    const sectionARef = useRef(null);

  return (
    <div className="App">
        <NavBar/>
        <Header linkRef={sectionARef}/>
        <div ref={sectionARef} className="section-a">
            <Row>
                <Col md={{size: 8, offset: 1}} className={"story"}>
                    <Story />
                </Col>
                <Col md={3}>
                    <Skills />
                </Col>
            </Row>
            <Row className="pt-5">
                <Col md={{size:9, offset: 1}}>
                   <Experience />
                </Col>
            </Row>
        </div>
        <div className="section-b">
            <Projects />        
        </div>
        <Footer />
    </div>
  );
}

export default App;
