import React from 'react';
import {Card, Row, Col, CardImg } from "reactstrap";
import BudgetGif from '../Imgs/moolah-gif-min.gif'

export default function Projects() {
  return (
    <>
      <div className="background-img">
      </div>
      <div className="img-title">
        Projects
      </div>
      <div className="card-container">
        <Row>
          <Col lg={{size:3,offset:1}}>
            <Card style={{height:'100%'}}>
              <CardImg top width="100%" src={BudgetGif} alt="Card image cap" />
              <div  className={"p-2"}>
                <h4>Budgeting Web Application</h4>
                <span className="text-muted">React & Java (Spring)</span>
                <hr />
                GitHub: <a href={'https://github.com/Russelldan554/budget-frontend'}>Frontend</a> &nbsp;
                <a href={'https://github.com/Russelldan554/budget_backend'}>Backend</a>
                <br />
                Site: <a href={'https://moolah-budget.herokuapp.com/'}>Heroku</a>
                <span className="text-muted">(takes ~20 seconds to spin up)</span>
              </div>
            </Card>
          </Col>
          <Col lg={3}>
            <Card style={{height:'100%'}}>
              <CardImg top width="100%" src={require('../Imgs/snake-min.gif')} alt="Card image cap" />
              <div  className={"p-2"}>
                <h4>Snake</h4>
                <span className="text-muted">Java</span>
                <hr />
                GitHub: <a href={'https://github.com/Russelldan554/WebsiteProjects'}>Snake/Other Projects</a>
              </div>
            </Card>
          </Col>
          <Col lg={3}>
            <Card style={{height:'100%'}}>
              <CardImg top width="100%" src={require('../Imgs/jeopardy-min.gif')} alt="Card image cap" />
              <div  className={"p-2"}>
                <h4>Jeopardy</h4>
                <span className="text-muted">React</span>
                <hr />
                Jeopardy: <a href={'http://jeopardy.druss.biz'}>Play now!</a>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  )
}