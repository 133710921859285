import React from 'react';
import { Row, Col } from "reactstrap";

export default function Footer() {
  return(
    <div className="footer">
      <Row>
        <Col md={4}>
        </Col>
        <Col md={4}>
          Website Designed by Daniel Russell <br />
          Updated August 2021
        </Col>
      </Row>
    </div>
  )
}