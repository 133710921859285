import React from 'react';
import './Header.css';
import headshot from '../Imgs/Headshot.png'

export default function Header({ linkRef }) {
  return(
    <div className="header-container">
      <div className="header-img-container">
        <div className="header-img">
          <img alt="Headshot"  src={headshot} />
        </div>
      </div>
      <div className="header-title">
        <div className="pl-2" >
          <h2>Daniel Russell</h2>
          <h2 className="pl-3"> Software Developer </h2>

          <button className="story-button" onClick={() => linkRef.current.scrollIntoView()}>Learn More</button>

        </div>
      </div>
    </div>
  )
}