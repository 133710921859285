import React from 'react';

export default function Story() {
  return (
    <>
      <h4>My Story</h4>
        <hr className="hr-accent"/>
        <p>
          When I was younger, I started building computers for myself and my friends which led to my interest
          in programming and Computer Science. This led to me entering Montgomery College for my Associates in
          Computer Science and then to The University of Maryland University College for my Bachelors. I
          Graduated from UMUC in 2019 and shortly before that started my first software development job at
          Kasm Technologies in January of 2019.
        </p>
        <p>
          Exploring new technologies and creating full stack serverless applications is my current passion. I enjoy 
          Learning new services and languages to find the best possible solution for a given project. I pride myself 
          on being able to pick up new technologies quickly and adapt them to my needs. Recently this has led me to 
          serverless technology. Deploying web applications on serverless platforms allows them to easily scale without
          big overhead. Learning AWS and its services allowed me to help launch a new platform at Mosaic Learning during 
          the height of the pandemic. We created a virtual conferencing platform that provided help to previously large in person
          conferences by bringing them together online. 
        </p>
    </>
  );
}