import React from 'react';

export default function Skills() {
  return (
    <>
      <ul>
        <h4 className={"text-center"}>Skills</h4>
        <li>Javascript</li>
        <li>ReactJS</li>
        <li>AWS</li>
        <li>ECS</li>
        <li>Lambda</li>
        <li>API Gateway</li>
        <li>Elemental Media Services</li>
        <li>Serverless Framework</li>
        <li>Python</li>
        <li>Java</li>
        <li>Docker</li>
        <li>CSS</li>
        <li>HTML</li>
        <li>Postgres</li>
        <li>mySQL</li>
      </ul>
    </>
  );
}